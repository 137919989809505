import {
  AuthHttp,
  AuthParams,
  FeatureModuleStateService
} from '@elotech/angular-common';
import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'usuario-ident',
  templateUrl: './usuario-ident.component.html'
})
export class UsuarioIdentComponent {
  collapsed: boolean;
  hasEnter: boolean;
  usuarioLogado: string;
  routeMe: string = '/me';

  constructor(
    private featureModuleStateService: FeatureModuleStateService,
    private authParams: AuthParams,
    private authHttp: AuthHttp
  ) {}
  ngOnInit() {
    this.loadUser();
  }

  private loadUser(): void {
    this.authParams.onLoginObservers.subscribe(logged => {
      if (logged) {
        this.authHttp.getUsuarioLogado().subscribe(user => {
          this.usuarioLogado = user.usuario;
        });
      } else {
        this.usuarioLogado = null;
      }
    });
  }

  toggle() {
    this.collapsed = !this.collapsed;
    this.hasEnter = false;

    this.featureModuleStateService.alternateStatus('user');
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (this.collapsed) {
      this.hasEnter = true;
    }
  }

  public isCollapsed(): boolean {
    return this.collapsed;
  }

  public logoutAndGoToAuthForm(): boolean {
    this.authHttp.logoutAndGoToAuthForm();
    return false;
  }
}
