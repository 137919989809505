<section [class.modules]="isLogged">
  <elo-spinner></elo-spinner>
  <elo-notification></elo-notification>
  <exception-handler></exception-handler>
  <div *ngIf="isLogged">
    <header class="header-modules">
      <div class="container">
        <figure class="header-icon">
          <img src="{{ urlBrasao }}" />
        </figure>
        <h2 class="header-title">
          {{ entidadeCidade?.cidade?.descricao }}
        </h2>
        <usuario-ident></usuario-ident>
      </div>
    </header>
  </div>

  <main-section [enabled]="false">
    <div [class.content]="isLogged" [class.login-overflow]="!isLogged">
      <router-outlet></router-outlet>
    </div>
  </main-section>

  <elo-footer *ngIf="isLogged"></elo-footer>
</section>
