<ul class="header-features" (click)="toggle()">
  <li [ngClass]="{ active: collapsed, '': !collapsed }">
    <i class="fa fa-user"></i>
  </li>
</ul>

<div class="features-group">
  <aside class="features-user" [class.active]="isCollapsed()">
    <h2 class="title">{{ usuarioLogado }}</h2>
    <ul class="user-actions">
      <li>
        <a routerLink="{{ routeMe }}">
          <i class="fa fa-id-card-o"></i> Minha Conta
        </a>
      </li>
      <li>
        <a (click)="logoutAndGoToAuthForm()">
          <i class="fa fa-sign-out"></i> Sair
        </a>
      </li>
    </ul>
  </aside>
</div>
