import { CommonModule } from '@angular/common';

import { SpinnerModule } from '@elotech/angular-common';
import { EloNotificationsModule } from '@elotech/angular-common';
import { UsuarioModule } from './components/usuario/usuario.module';
import { SectionModule } from '@elotech/angular-common';

import { InterceptorsProviders } from '@elotech/angular-common';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';

import { AuthComponentService } from '../auth/auth-component.service';

import { GlobalExceptionHandlerService } from '@elotech/angular-common';
import { CanDeactivateGuard } from '@elotech/angular-common';
import { CanActivateRouteService } from '@elotech/angular-common';
import { CanActivateRoute } from '@elotech/angular-common';
import { HttpClient } from '@elotech/angular-common';
import { AuthHttp } from '@elotech/angular-common';
import { AuthParams } from '@elotech/angular-common';
import { ErrorModule } from '@elotech/angular-common';
import { AUTH_RESOURCES_CONFIG, AUTH_RESOURCES_CONFIG_UNICO } from '@elotech/angular-common';
import { UrlResolver } from '@elotech/angular-common';
import { SpinnerService } from '@elotech/angular-common';
import { CanActivateAdminRoute } from './services/can-activate-admin-route.service';
import { environment } from '../environments/environment';
import { FeatureModuleStateService } from '@elotech/angular-common';
import { RouterService } from '@elotech/angular-common';
import { AbstractAuthHttp } from '@elotech/angular-common';
import { HttpClientModule } from '@angular/common/http';

export class UrlResolverLogin extends UrlResolver {
  constructor() {
    super(environment.apiUrl);
  }
}

export function createAuthResourceLogin() {
  return Object.assign(AUTH_RESOURCES_CONFIG_UNICO,
    {
      isDev: !environment.production,
      urlForm: '/auth'
    });
}

@NgModule({
  imports: [],
  exports: [
    CommonModule,
    UsuarioModule,
    SpinnerModule,
    SectionModule,
    EloNotificationsModule,
    HttpClientModule,
    ErrorModule
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [AuthParams, SpinnerService, AuthHttp, HttpClient,
        ...InterceptorsProviders.providersWithHeaderParams(),
        CanActivateRouteService,
        AuthComponentService,
        CanActivateAdminRoute,
        CanDeactivateGuard,
        FeatureModuleStateService,
        RouterService,
        { provide: CanActivateRoute, useClass: CanActivateRouteService },
        { provide: AbstractAuthHttp, useClass: AuthHttp },
        { provide: UrlResolver, useClass: UrlResolverLogin },
        { provide: ErrorHandler, useClass: GlobalExceptionHandlerService },
        { provide: AUTH_RESOURCES_CONFIG, useFactory: createAuthResourceLogin }
      ]
    };
  }
}
