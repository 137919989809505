import { CommonModule } from '@angular/common';
import { FeaturesGroupModule } from '@elotech/angular-common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UsuarioIdentComponent } from './usuario-ident.component';
import { UsuarioService } from 'projects/login/src/usuario/usuario.service';

@NgModule({
  imports: [CommonModule, RouterModule, FeaturesGroupModule],
  declarations: [UsuarioIdentComponent],
  exports: [UsuarioIdentComponent]
})
export class UsuarioModule {}
