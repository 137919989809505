import { Component, OnInit } from '@angular/core';

import { AuthComponentService } from './auth/auth-component.service';
import { AuthHttp } from '@elotech/angular-common';
import { Usuario } from '@elotech/angular-common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  entidadeCidade: any;
  urlBrasao: string = '';
  nomeEntidade: string;
  usuario: Usuario = null;
  isAdmin: boolean = false;

  constructor(
    private authComponentService: AuthComponentService,
    public authHttp: AuthHttp
  ) {}

  ngOnInit(): void {
    this.urlBrasao = this.authComponentService.getUrlBrasao();
    this.authComponentService
      .getCidade()
      .subscribe(entidadeCidade => (this.entidadeCidade = entidadeCidade));
    this.authHttp.authParams.onLoginObservers.subscribe(value => {
      this.isAdmin = false;
      if (value) {
        this.authHttp.isAdmin().subscribe(isAdmin => {
          this.isAdmin = isAdmin;
        });
      }
    });
  }

  get isLogged(): boolean {
    return this.authHttp.authParams.isLogged();
  }

  logout() {
    this.authHttp.logoutAndGoToAuthForm();
  }
}
